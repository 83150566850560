import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getCaseStudyPathFromSlug } from '../../util/paths';
import { caseStudyTileStyles } from './caseStudyTiles.module.scss';

const CaseStudyTile = ({ href, client, name, image, ...props }) => {
  const classNames = [caseStudyTileStyles, props?.className];
  const img = getImage(image?.asset) || null;
  if (!href && props.slug) {
    href = getCaseStudyPathFromSlug(props.slug);
  }
  return (
    <article {...props} className={classNames.join(' ')}>
      <a href={href}>
        <section>{img && <GatsbyImage image={img} alt={name} objectFit='cover' />}</section>
        <section>
          <h1>{client}</h1>
          <h2>{name}</h2>
        </section>
      </a>
    </article>
  );
};

export default CaseStudyTile;
