import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import { fullscreenStyle } from './fullscreen.module.scss';

const FullbleedHero = ({ title, subtitle, image }) => {
  const imageAsset = image?.asset ? getImage(image.asset) : getImage(image) || null;

  const palette = { ...image?.metadata?.palette?.main };
  const style = {
    '--palette-background': palette?.background,
    '--palette-foreground': palette?.foreground,
  };

  return (
    <header data-layout-hero data-use-palette data-module className={fullscreenStyle} style={style}>
      <figure>
        <GatsbyImage image={imageAsset} alt='' />
        <figcaption>
          {title && <h1>{parse(title)}</h1>}
          {subtitle && <h2>{parse(subtitle)}</h2>}
        </figcaption>
      </figure>
    </header>
  );
};

export default FullbleedHero;
