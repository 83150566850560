import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { maskedImageStyle } from './maskedImage.module.scss';

const MaskedImageHero = ({ title, subtitle, mask, images }) => {
  const maskURL = mask?.asset.url;
  const [bgImage, setBgImage] = useState();

  useEffect(() => {
    const shuffled = [...images].sort(() => Math.random() - 0.5);
    setBgImage(shuffled[0].asset.url);
  }, [images]);

  const style = {
    '--image': `url("${bgImage}")`,
    '--mask': `url("${maskURL}")`,
  };

  return (
    <header data-layout-hero data-use-palette className={maskedImageStyle}>
      <figure>
        <div style={style}></div>
        <figcaption>
          {title && <h1>{parse(title)}</h1>}
          {subtitle && <h2>{parse(subtitle)}</h2>}
        </figcaption>
      </figure>
    </header>
  );
};

export default MaskedImageHero;
