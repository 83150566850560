// ToDo Move config object into constants or global config

import React from 'react';
import ReactPlayer from 'react-player';

export default ({ url }) => (
  <ReactPlayer
    controls
    url={url}
    width='100%'
    height='100%'
    config={{
      vimeo: {
        playerOptions: {
          responsive: true,
          byline: false,
          title: false,
          dnt: true,
        },
      },
    }}
  />
);
